import axios, { type AxiosResponse } from "axios";

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_HOST,
  timeout: 10000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  },
});

export function getData<T>(response: AxiosResponse<T>): T {
  return response.data;
}

export default apiClient;
