import { createApp } from "vue";
import i18n from "@/common/helpers/i18n";
import * as Sentry from "@sentry/vue";
import Toast from "@/common/plugins/toast";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// styles
import "@/styles/main.scss";
import "./twstyles/styles.css";

const app = createApp(App);

if (import.meta.env.VITE_APP_TRACKING_ENV === "production") {
  const release = VITE_APP_VERSION;
  Sentry.init({
    app,
    release,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    beforeSend(event: Sentry.Event) {
      if (event.exception && event.exception.values) {
        for (const value of event.exception.values) {
          if (
            /Failed to fetch dynamically imported module/.test(
              value.value || ""
            ) ||
            /vite:preloadError/.test(value.value || "") ||
            /Importing a module script failed/.test(value.value || "")
          ) {
            return null;
          }
        }
      }
      return event;
    },
  });
}

app.use(router);
app.use(store);
app.use(Toast);
app.use(i18n);

app.mount("#app");

app.config.errorHandler = (err, vm, info) => {
  console.error("Globlal vue file error captured:", err, info);
};
