import apiClient, { getData } from "@/common/helpers/apiClient";
import { ORDERS, OPERATIONS, TIMELINE_GROUPS } from "./paths.js";

export function showOrder(reference: string) {
  return apiClient.get(`${ORDERS}/${reference}`).then(getData<Order[]>);
}

export function showOrderByOperation(
  opReference: string,
  clientOrderId: string
) {
  return apiClient
    .get(`${OPERATIONS}/${opReference}${ORDERS}/${clientOrderId}`)
    .then(getData<Order[]>);
}

export function indexAvailableDays(orderReference: string) {
  return apiClient
    .get(`${ORDERS}/${orderReference}/available-days`)
    .then(getData<AvailableDays[]>);
}

export function changeDeliveryTime({
  orderReference,
  date,
}: {
  orderReference: string;
  date: string;
}) {
  return apiClient
    .post(`${ORDERS}/${orderReference}/delivery-time`, date)
    .then(getData<AvailableDays[]>);
}

export function indexTimelimeGroups() {
  return apiClient.get(`${TIMELINE_GROUPS}`).then(getData<TimelineGroup[]>);
}
