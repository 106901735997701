export const TYPE_MAP: Readonly<string> = "light-v10";
export const ZOOM_MIN_DEFAULT: Readonly<number> = 4;
export const ZOOM_MIN: Readonly<number> = 6;
export const ZOOM_MAX_DEFAULT: Readonly<number> = 16;
export const ZOOM_MAX: Readonly<number> = 18;
export const ICON_MAP: Readonly<string> = `${window.location.origin}/marker.png`;
export const LATITUDE_DEFAULT: Readonly<string> = "40.4399";
export const LONGITUDE_DEFAULT: Readonly<string> = "-3.6832";

export const ROUTE_NAME_DELIVERY: Readonly<string> = "delivery";
export const ROUTE_NAME_DELIVERY_OPERATION: Readonly<string> =
  "deliveryOperation";
