import { createToast, error, success } from "../helpers/toasts";

export default {
  install: (app) => {
    const methods = {
      error: (settings) => createToast(error, settings),
      success: (settings) => createToast(success, settings),
      warning: (settings) => createToast(success, settings),
    };
    app.config.globalProperties.$toast = methods;
    app.provide("toast", methods);
  },
};
